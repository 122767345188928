import { Tab, Tabs } from "@mui/material";
import { Box, CSSObject } from "@mui/system";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useStyles as sharedStyles } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface TabsCustomProps {
  setValue: Dispatch<SetStateAction<number>>;
  value: number;
  tabsArray: string[];
  customStyle?: CSSObject;
}

const TabsCustom = ({
  setValue,
  value,
  tabsArray,
  customStyle,
}: TabsCustomProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const { customTab, tabsHolder } = sharedStyles(colors);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <Box
      data-testid="tabs-box"
      sx={[
        {
          borderBottom: 1,
          borderColor: "divider",
        },
        customStyle as CSSObject,
      ]}
    >
      <Tabs
        TabIndicatorProps={{ style: { background: "#0054A6" } }} // override selected item box shadow
        value={value}
        onChange={handleChange}
        aria-label="details tabs"
        data-testid="tabs-holder"
        sx={tabsHolder as CSSObject}
      >
        {tabsArray.map((tab: string, index) => (
          <Tab
            key={tab}
            label={t(`Details##${tab}`)}
            sx={[customTab as CSSObject]}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabsCustom;
