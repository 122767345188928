export enum ALERT_STATUS {
  Info = "info",
  Critical = "critical",
  Warning = "warning",
  Success = "success",
}

export enum ALERT_MESSAGES {
  actionFailed = "Action failed due to error",
}

export interface AlertInitialState {
  status: ALERT_STATUS | null;
  title: string | null;
  description: string | null;
}
