import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import VehicleGeneralDetails from "../components/GeneralDetails/VehicleGeneralDetails";
import DriverGeneralDetails from "../components/GeneralDetails/DriverGeneralDetails";
import CompaniesGeneralDetails from "../components/GeneralDetails/CompaniesGeneralDetails";
import ResourceScheduleCards from "../components/ResourceScheduleCards/ResourceScheduleCards";
import CompanyCardsDetails from "../components/CompanyCardsDetails/CompanyCardsDetails";
import EmailTransferDetails from "../components/EmailTransferDetails/EmailTransferDetails";

import { ROUTES } from "../../../../../shared";
import { emailTransferDetailsState } from "../../../../../atoms/atoms";

const useDetailsContent = () => {
  const location = useLocation();

  const emailTransferDetails = useRecoilValue(emailTransferDetailsState);

  const renderGeneralDetails = (detailsData: any) => {
    switch (location.pathname) {
      case ROUTES.Vehicles:
        return <VehicleGeneralDetails detailsData={detailsData} />;
      case ROUTES.Drivers:
        return <DriverGeneralDetails detailsData={detailsData} />;
      case ROUTES.Companies:
        return <CompaniesGeneralDetails detailsData={detailsData} />;

      default:
        return <></>;
    }
  };

  const renderSchedules = (detailsData: any, displayLoader: boolean) => {
    const validRoutes = [ROUTES.Vehicles, ROUTES.Drivers];

    if (!validRoutes.includes(location.pathname as ROUTES)) {
      return <></>;
    }

    return (
      <ResourceScheduleCards
        detailsData={detailsData}
        isLoading={displayLoader}
        pageLocation={location.pathname as ROUTES}
      />
    );
  };

  const renderCompanyCards = (detailsData: any, displayLoader: boolean) =>
    location.pathname === ROUTES.Companies ? (
      <CompanyCardsDetails
        detailsData={detailsData}
        isLoading={displayLoader}
      />
    ) : (
      <></>
    );

  const renderFileSyncContent = () => {
    return <></>;
  };

  const renderEmailTransferContent = () => {
    return (
      emailTransferDetails && (
        <EmailTransferDetails emailTransferDetails={emailTransferDetails} />
      )
    );
  };

  return {
    renderFileSyncContent,
    renderGeneralDetails,
    renderSchedules,
    renderCompanyCards,
    renderEmailTransferContent,
  };
};

export default useDetailsContent;
