/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface DetailsContainerProps {
  contentHeight: string;
  children?: React.ReactNode;
  toMd?: boolean;
}

const DetailsContainer = ({
  contentHeight,
  children,
  toMd,
}: DetailsContainerProps) => {
  return (
    <div
      css={css({
        height: contentHeight,
        overflowY: "scroll",
        padding: toMd ? "0 16px 0 0" : "unset",
      })}
    >
      {children}
    </div>
  );
};

export default DetailsContainer;
