/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useContext } from "react";

import { useStyles } from "../../../../../styles";

import Button from "../../../../../../Button/Button";

import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";

interface TabsArrowButtonProps {
  onButtonClick: () => void;
  isDisabled: boolean;
  leftButton?: boolean;
}

const TabsArrowButton = ({
  onButtonClick,
  isDisabled,
  leftButton,
}: TabsArrowButtonProps) => {
  const { colors } = useContext(ThemeContext);
  const {
    leftTabsArrowButton,
    rightTabsArrowButton,
    customTabsArrowButtonBorder,
  } = useStyles(colors);

  const buttonStyle = leftButton ? leftTabsArrowButton : rightTabsArrowButton;
  const icon = leftButton ? (
    <ChevronLeftOutlinedIcon data-testid="left-arrow-icon" />
  ) : (
    <ChevronRightOutlinedIcon data-testid="right-arrow-icon" />
  );

  return (
    <Button
      data-testid="tabs-arrow-button"
      onClick={onButtonClick}
      variant="iconOnly"
      icon={icon}
      color="secondary"
      size="small"
      disabled={isDisabled}
      css={css([buttonStyle, customTabsArrowButtonBorder])}
    />
  );
};

export default TabsArrowButton;
