import { useTranslation } from "react-i18next";

import ScheduleDetailsSingleRow from "./ScheduleDetailsSingleRow";
import { DriverScheduleDto, ScheduleDto } from "../../../../../../types";
import useScheduleRepeatValues from "../../../../../../../Schedules/hooks/useScheduleRepeatValues/useScheduleRepeatValues";
import VehicleDataTypesList from "../../../../../../../Schedules/components/VehicleDataTypesList/VehicleDataTypesList";
import { useDateTime } from "../../../../../../../../shared";

interface ScheduleDetailsContentProps {
  schedule: DriverScheduleDto | ScheduleDto;
  isDriversPage?: boolean;
}

const ScheduleDetailsContent = ({
  schedule,
  isDriversPage,
}: ScheduleDetailsContentProps) => {
  const { t } = useTranslation();
  const { formattedDate } = useDateTime();
  const { getRepeatString } = useScheduleRepeatValues();

  const {
    customRepeat,
    fileTypes,
    period,
    repeat,
    scheduleStartTime,
    nextDueDate,
    quick,
  } = schedule as ScheduleDto & DriverScheduleDto;

  const repeatValue = isDriversPage ? period : repeat;

  const detailsRows = [
    {
      title: t("Dialog##name"),
      content: schedule?.name,
    },
    {
      title: t("Schedules##start date"),
      content: formattedDate(scheduleStartTime),
    },
  ];

  return (
    <>
      {detailsRows.map((row) => {
        return (
          <ScheduleDetailsSingleRow
            key={row.title}
            title={row.title}
            content={row.content}
          />
        );
      })}

      {!quick && (
        <>
          <ScheduleDetailsSingleRow
            title={t("Schedules##next due date")}
            content={formattedDate(nextDueDate)}
          />
          <ScheduleDetailsSingleRow
            title={t("Schedules##repeat")}
            content={getRepeatString(repeatValue, customRepeat)}
          />
        </>
      )}

      {fileTypes && (
        <ScheduleDetailsSingleRow
          title={t("Schedules##data types")}
          dataTypes={
            <VehicleDataTypesList
              schedule={schedule as ScheduleDto}
              size="medium"
            />
          }
        />
      )}
    </>
  );
};

export default ScheduleDetailsContent;
