/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import DetailsCardItemTitle from "../../../DetailsCardItemTitle";
import DetailsCardItemText from "../../../DetailsCardItemText";

import { useStyles } from "../../../../../../styles";
import { ThemeContext } from "../../../../../../../../context/theme/ThemeContextProvider";
import { EmailTransferObjectDto } from "../../../../../../types";

interface ISingleEmailTransferContentProps {
  card: EmailTransferObjectDto;
}

const SingleEmailTransferContent = ({
  card,
}: ISingleEmailTransferContentProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { paperCardItem } = useStyles(colors);

  return (
    <>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Details##file format")} />
        <DetailsCardItemText textToRender={`.${card.fileFormat}`} />
      </div>
    </>
  );
};

export default SingleEmailTransferContent;
