import { useState } from "react";

import EmailTransferActions from "./EmailTransferActions/EmailTransferActions";

import SingleEmailTransferContent from "../SingleEmailTransferContent/SingleEmailTransferContent";

import SingleDetailsAcordionItem from "../../../AccordionDetailsCard/components/SingleDetailsAcordionItem";

import { EmailTransferObjectDto } from "../../../../../../types";

import { MenuAnchorEl } from "../../../../../../../../shared";
import { MORE_ACTIONS_MENU_SIZES } from "../../../../../../../../shared/components/buttons/types";

interface SingleEmailTransferItemProps {
  card: EmailTransferObjectDto;
  totalCount: number;
  index: number;
}

const SingleEmailTransferItem = ({
  card,
  totalCount,
  index,
}: SingleEmailTransferItemProps) => {
  const [isActionsOpen, setIsActionsOpen] = useState<MenuAnchorEl>(null);
  return (
    <SingleDetailsAcordionItem
      title={card.email}
      totalCount={totalCount}
      index={index}
      collapsable
      anchorEl={isActionsOpen}
      setIsActionsOpen={setIsActionsOpen}
      titleInlineActions={<EmailTransferActions />}
      menuWidth={MORE_ACTIONS_MENU_SIZES.Small}
    >
      <SingleEmailTransferContent card={card} />
    </SingleDetailsAcordionItem>
  );
};

export default SingleEmailTransferItem;
