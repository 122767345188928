import { CSSObject } from "@emotion/react";
import { NAVBAR_HEIGHT, ThemeColors } from "../../shared";

export const useStyles = (colors?: ThemeColors) =>
  ({
    desktopDetailsAside: {
      position: "fixed",
      zIndex: 1200,
      right: "0",
      top: NAVBAR_HEIGHT,
      bottom: "0",
      boxShadow: "0px 1px 4px 0px rgba(15, 23, 42, 0.12)",
      height: "unset",
      overflowY: "unset",
    },
    detailsContentContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    detailsPaperCard: {
      margin: "24px 16px 24px 24px",
      boxShadow: "none",
      backgroundColor: "white",
      borderRadius: "8px",
    },
    firstCollapsablePaperCard: {
      margin: "8px 16px 24px 24px",
      boxShadow: "none",
      backgroundColor: "white",
      borderRadius: "8px",
    },
    detailsPaperCardContent: {
      margin: "16px",
    },
    paperCardTitle: {
      marginBottom: "16px",
    },
    paperCardItem: {
      display: "flex",
      marginBottom: "16px",
      alignItems: "center",
      wordBreak: "break-word",
    },
    paperCardItemTitle: {
      width: "112px",
    },
    paperCardItemFixedHeight: {
      height: "24px",
    },
    paperCardItemAlignUnset: {
      alignItems: "unset",
    },
    paperCardItemAlignStart: {
      alignItems: "start",
    },
    collapsableTitleArea: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    collapseArrow: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    dataTypeIconsBlock: {
      display: "flex",
      "& > span": { marginRight: "4px" },
    },
    detailsTitle: {
      paddingBottom: "8px",
      width: "100%",
      minWidth: "300px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    detailsBlockTitleArea: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "22px",
    },
    actionTextLinkBlue: {
      color: colors?.blue700,
      marginTop: "16px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    actionTextLinkRed: {
      color: colors?.red800,
      marginTop: "8px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    detailsBannerMessage: { margin: "8px 6px 24px 24px" },
    statusTitle: {
      lineHeight: "24px",
    },
    customTabsContainer: {
      marginTop: "16px",
      display: "flex",
      width: "100%",
      overflow: "hidden",
    },
    customTabsArrowButtonBorder: {
      borderBottom: `1px solid ${colors?.gray200}`,
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
    },
    leftTabsArrowButton: {
      width: "24px",
      background:
        "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",
      "&.Mui-disabled": {
        background:
          "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",
      },

      "&.Mui-focusVisible, &:hover, &:active, &:focus": {
        background:
          "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",
      },
    },

    rightTabsArrowButton: {
      width: "28px", // 24px padding + 4px addition due to scroll bar
      background:
        "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",

      "&.Mui-disabled": {
        background:
          "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",
      },
      "&.Mui-focusVisible, &:hover, &:active, &:focus": {
        background:
          "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",
      },
    },
  } as CSSObject);
