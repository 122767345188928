interface TabCustomPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const TabCustomPanel = ({ children, value, index }: TabCustomPanelProps) => {
  return <div>{value === index && <>{children}</>}</div>;
};

export default TabCustomPanel;
