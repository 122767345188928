/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Paper } from "@mui/material";
import { ReactNode, useContext } from "react";
import { useRecoilValue } from "recoil";

import AccordionDetailsCardTitleArea from "./components/AcordionDetailsCardTitleArea";
import { useStyles } from "../../../../styles";
import {
  tableDetailsLoading,
  tableLoading,
} from "../../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";

export interface AccordionDetailsCardProps {
  cardTitle?: string;
  children: ReactNode;
  maxCount?: number;
  currentCount?: number;
  accordionItems: any[];
  quickTitleAction?: ReactNode;
  titleInlineIcon?: ReactNode;
}

const AccordionDetailsCard = ({
  cardTitle,
  maxCount,
  currentCount,
  accordionItems,
  quickTitleAction,
  children,
  titleInlineIcon,
}: AccordionDetailsCardProps) => {
  const { colors } = useContext(ThemeContext);

  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);

  const { firstCollapsablePaperCard } = useStyles();

  const displayLoader = isDetailsLoading || isTableLoading;

  return (
    <Paper
      data-testid="acordion-details-card"
      id="acordion-details-card"
      css={css([
        firstCollapsablePaperCard,
        {
          border: `1px solid ${colors.gray200}`,
          margin: "24px 16px 24px 24px",
        },
      ])}
    >
      <AccordionDetailsCardTitleArea
        title={cardTitle || ""}
        displayLoader={displayLoader}
        colors={colors}
        titleInlineAddition={
          maxCount ? `(${currentCount}/${maxCount})` : undefined
        }
        titleInlineIcon={titleInlineIcon}
        quickAction={quickTitleAction}
      />
      {children}
    </Paper>
  );
};

export default AccordionDetailsCard;
