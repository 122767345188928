import DetailsCard from "../../DetailsCard";

import DetailsContainer from "../../../../DetailsContainer/DetailsContainer";

interface ContentLoaderProps {
  contentHeight: string;
  isLoading: boolean;
}

const ContentLoader = ({ contentHeight, isLoading }: ContentLoaderProps) => {
  return (
    <DetailsContainer contentHeight={contentHeight}>
      <DetailsCard cardTitle="" contentLoading={isLoading}>
        <></>
      </DetailsCard>
    </DetailsContainer>
  );
};

export default ContentLoader;
