/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

import ColoredCircles from "./ColoredCircles";
import { useStyles } from "../styles";
import { TITLES } from "../types";
import { ROUTES } from "../../..";
import Button from "../../../../components/Button/Button";
import Logo from "../../../../components/Logo/Logo";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";

interface IErrorCOmponentProps {
  onButtonClick: () => void;
  title: TITLES;
  animation: any;
  message?: string;
  pathname: string;
}

const ErrorComponent = ({
  onButtonClick,
  title,
  animation,
  message,
  pathname,
}: IErrorCOmponentProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const {
    headerWithLogo,
    animationCSS,
    errorMainContainer,
    errorAnimatedBlock,
    errorMainTitle,
    errorDescriptionArea,
  } = useStyles(colors);

  return (
    <>
      <header css={css(headerWithLogo)}>
        <Logo />
      </header>
      <main>
        <section className="remaining-height">
          <div className="remaining-height" css={css(errorMainContainer)}>
            <div css={css(errorAnimatedBlock)}>
              <ColoredCircles />
              <div>
                {title === TITLES.Error && (
                  <div css={css(errorMainTitle)}>{t(`${title}##error`)}</div>
                )}
              </div>
              {/* animation */}
              <Lottie
                data-testid="lottie"
                animationData={animation}
                loop
                alt={title}
                css={css(animationCSS)}
              />
            </div>
            <div css={css(errorDescriptionArea)}>
              <h1>{t(`${title}##title`)}</h1>
              {message && <p>{t(message)}</p>}
              <div>
                <Button
                  data-testid={"navigation-button"}
                  color="primary"
                  size="normal"
                  variant="textOnly"
                  onClick={onButtonClick}
                >
                  {pathname === ROUTES.Dashboard
                    ? t("Button##refresh page")
                    : t("Button##return home")}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ErrorComponent;
