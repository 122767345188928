/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";

import { EmailTransferObjectDto } from "../../../../../../types";

import Tooltip from "../../../../../../../Tooltip/Tooltip";
import Button from "../../../../../../../Button/Button";

import { COMPANY_CARDS_LIMITS } from "../../../../../../../../Pages/Companies/types";

interface AddNewEmailButtonProps {
  emailTransferDetails: EmailTransferObjectDto[];
  emailCount: number;
}

const AddNewEmailButton = ({ emailCount }: AddNewEmailButtonProps) => {
  const { t } = useTranslation();

  const isDisabled = emailCount >= COMPANY_CARDS_LIMITS.MaxEmailTransferCount;

  const handleAddEmailClick = () => {
    console.log("Add new email button clicked");
  };

  return (
    <>
      <Tooltip
        title={t(`Details##email limit reached`, {
          currCount: emailCount,
          maxCount: COMPANY_CARDS_LIMITS.MaxEmailTransferCount,
        })}
        placement="top-end"
        hasArrow
        size="medium"
        disableHoverListener={!isDisabled}
      >
        <span>
          <Button
            data-testid="add-new-email-button"
            color="secondary"
            onClick={handleAddEmailClick}
            size="tiny"
            variant="iconLeft"
            disabled={isDisabled}
            icon={<AddOutlinedIcon css={css({ marginRight: "-2px" })} />}
          >
            {t("Company cards##add new")}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default AddNewEmailButton;
