import EditChoice from "../../../../../../../../../shared/components/sharedListChoices/EditChoice/EditChoice";

const EmailTransferActions = () => {
  return (
    <>
      <EditChoice
        testId="edit-email-transfer-option"
        onButtonClick={() => console.log("edit email transfer option clicked")}
      />
    </>
  );
};

export default EmailTransferActions;
