import { useRecoilState } from "recoil";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import useResponse from "./useResponse";

import useAxiosPrivate from "../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../api/resources";
import {
  companyCardsState,
  deviceStatusState,
  initialLoading,
  newsDataState,
  resetDetailsSectionOnPageChange,
  tableLoading,
} from "../../atoms/atoms";
import { AlertContext } from "../../context/alert/AlertContextProvider";
import { ALERT_MESSAGES } from "../../context/alert/types";

const useRefreshDashboardData = () => {
  const { alertMessage } = useContext(AlertContext);

  const { handleCommonError } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();

  const [newsData, setNewsData] = useRecoilState(newsDataState);
  const [, setDeviceStatus] = useRecoilState(deviceStatusState);
  const [, setCompanyCards] = useRecoilState(companyCardsState);
  const [, setIsTableLoading] = useRecoilState(tableLoading);
  const [, setResetDetailsOnPageChange] = useRecoilState(
    resetDetailsSectionOnPageChange
  );
  const [, setIsInitialLoading] = useRecoilState(initialLoading);

  const refreshNewsData = async () => {
    try {
      setIsTableLoading(true);
      const response = await getData(ApiResources.NewsItems);
      setNewsData(response.data);
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsTableLoading(false);
    }
  };

  const refreshDeviceStatusData = async () => {
    try {
      setIsTableLoading(true);
      // reset value to null to remove flash of old data
      setDeviceStatus(null);
      const response = await getData(ApiResources.DashboardDevices);
      setDeviceStatus(response.data);
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsTableLoading(false);
    }
  };

  const refreshCompanyCardsData = async () => {
    try {
      setIsTableLoading(true);
      // reset value to null to remove flash of old data
      setCompanyCards(null);
      const response = await getData(ApiResources.DashboardCompanyCards);
      setCompanyCards(response.data);
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsTableLoading(false);
    }
  };

  const refreshDashboardData = () => {
    refreshNewsData();
    refreshDeviceStatusData();
    refreshCompanyCardsData();
  };

  const finishLoadingIfAllDataIsFetched = useCallback(() => {
    if (newsData || alertMessage?.title === ALERT_MESSAGES.actionFailed) {
      setIsInitialLoading(false);
      setResetDetailsOnPageChange(false);
    }
  }, [
    newsData,
    setIsInitialLoading,
    setResetDetailsOnPageChange,
    alertMessage,
  ]);

  // the function below is used to reset the data when the page is changed,
  // so that the loading state is shown again
  const resetDashboardDataOnPageChange = useCallback(() => {
    setNewsData(null);
    setDeviceStatus(null);
    setCompanyCards(null);
  }, [setNewsData, setDeviceStatus, setCompanyCards]);

  return {
    refreshDashboardData,
    resetDashboardDataOnPageChange,
    finishLoadingIfAllDataIsFetched,
    refreshNewsData,
    refreshDeviceStatusData,
    refreshCompanyCardsData,
  };
};

export default useRefreshDashboardData;
