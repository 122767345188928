export enum COMPANY_LEVELS {
  MaxCompaniesLevels = 4,
}

export enum COMPANY_INPUT_FIELDS {
  CompanyName = "company name",
}

export enum COMPANY_CARDS_LIMITS {
  MaxCompanyCardsCount = 5,
  MaxEmailTransferCount = 5,
}
