export enum DETAILS_TITLES {
  Schedules = "schedules",
  CompanyCards = "company cards",
}

export enum COMPANY_DETAILS_TABS {
  Details = "details",
  FileSync = "file sync",
  EmailTransfer = "email transfer",
}
