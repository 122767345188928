import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../api/resources";
import useResponse from "../../../../../shared/hooks/useResponse";
import {
  emailTransferDetailsState,
  tableDetailsLoading,
  tableLoading,
} from "../../../../../atoms/atoms";

const useCompaniesTabDetails = () => {
  const { getData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();
  const { t } = useTranslation();

  const [, setEmailTransferdetails] = useRecoilState(emailTransferDetailsState);
  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);

  const [isLoading, setIsLoading] = useState(false);

  const getTabDetailsData = useCallback(
    async (companyId: string, resource: ApiResources) => {
      const emailTransferTab = resource === ApiResources.EmailRecipients;

      setIsLoading(true);
      setEmailTransferdetails(null);
      try {
        await getData(`${resource}/${companyId}`).then((response) => {
          emailTransferTab && setEmailTransferdetails(response.data);
        });
      } catch (error) {
        handleCommonError(error, t);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getData,
      handleCommonError,
      setEmailTransferdetails,
      isTableLoading,
      isDetailsLoading,
    ]
  );

  return { getTabDetailsData, isLoading };
};

export default useCompaniesTabDetails;
