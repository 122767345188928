import { atom } from "recoil";

import {
  SearchQueryParamsStateType,
  TableDetailsDataStateType,
  TableRowsPerPageAtomType,
  TutorialDetailsType,
} from "./types";
import { getItemFromLocalStorage } from "./../shared/functions/functions";
import { TABLE_NAMES } from "./../shared/types/enums";

import {
  Column,
  CompaniesDto,
  LOCAL_STORAGE_KEYS,
  TablePageDataDto,
  TableRowData,
  UserSettingsDto,
} from "../shared";
import { getTokensFromLocalStorage } from "../shared/functions/auth";
import { UserDto } from "../Pages/Auth/types";
import {
  DetailsDto,
  EmailTransferObjectDto,
} from "../components/DetailsSection/types";
import { FilterParamsType } from "../components/Filters/types/types";
import { ScheduleDataDto } from "../components/Schedules/types";
import { NewsDto } from "../Pages/Dashboard/LeftSide/News/types";
import { STEP_GROUP } from "../components/TutorialMode/types";
import { DeviceStatusDto } from "../Pages/Dashboard/RightSide/DeviceStatus/types";
import { CompanyCardDataDto } from "../Pages/Dashboard/RightSide/CompanyCards/types";

export const pagesDefaultValue = (value: null | string) => {
  return {
    vehiclesPage: value,
    driversPage: value,
    vehicleFilesPage: value,
    driverFilesPage: value,
    usersPage: value,
    usersInvitationsPage: value,
    companiesPage: value,
  };
};

export const mobileDrawerState = atom({
  key: "mobileDrawerState",
  default: false,
});

export const desktopDrawerState = atom({
  key: "desktopDrawerState",
  default:
    getItemFromLocalStorage(LOCAL_STORAGE_KEYS.DesktopDrawer) === "true"
      ? true
      : false,
});

export const currLocation = atom<any>({
  key: "currLocation",
  default: "/",
});

export const tokensAtom = atom<any>({
  key: "tokensAtom",
  default: getTokensFromLocalStorage(),
});

export const userAtom = atom<UserDto | null>({
  key: "userAtom",
  default: null,
});

export const userSettingsAtom = atom<UserSettingsDto>({
  key: "userSettingsAtom",
  default: {
    fileFormat: "",
    timeSettings: {
      dateFormat: null,
      timeZone: null,
    },
  },
});

export const twoFactorVerificationState = atom<boolean>({
  key: "twoFactorVerificationState",
  default: false,
});

export const loginState = atom<boolean>({
  key: "loginState",
  default: false,
});

// =====================
// TABLE ATOMS:

export const tableLoading = atom<boolean>({
  key: "tableLoading",
  default: true,
});

export const tableColumns: Column | Column[] | any = atom({
  key: "tableColumns",
  default: [],
});

export const tableData = atom<TableRowData[]>({
  key: "tableData",
  default: [],
});

export const tablePageData = atom<null | TablePageDataDto>({
  key: "tablePageData",
  default: null,
});

export const page = atom<number | string | null>({
  key: "page",
  default: null,
});

export const currPage = atom<any>({
  key: "pageNumber",
  default: pagesDefaultValue(null),
});

export const selectedTableRows = atom<string[]>({
  key: "selectedTableRows",
  default: [],
});

export const tableHeadActivity = atom<boolean>({
  key: "tableHeadActivity",
  default: false,
});

export const tableName = atom<TABLE_NAMES | undefined>({
  key: "tableName",
  default: undefined,
});

export const tableRowsPerPage = atom<TableRowsPerPageAtomType>({
  key: "tableRowsPerPage",
  default: pagesDefaultValue("25"),
});

export const initialLoading = atom<boolean>({
  key: "initialLoading",
  default: true,
});

export const rowsEnabledToSelect = atom<any[]>({
  key: "rowsEnabledToSelect",
  default: [],
});

export const tableSortedColumn = atom({
  key: "tableSortedColumn",
  default: pagesDefaultValue(""),
});

export const isTableCheckable = atom<boolean>({
  key: "isTableCheckable",
  default: true,
});

// TABLE DETAILS SECTION

export const tableDetailsResource = atom<string | undefined>({
  key: "tableDetailsResource",
  default: undefined,
});

export const tableDetailsData = atom<DetailsDto | null>({
  key: "tableDetailsData",
  default: null,
});

export const tableDetails = atom<TableDetailsDataStateType | null>({
  key: "tableDetails",
  default: {
    vehiclesPage: null,
    driversPage: null,
    companiesPage: null,
  },
});

export const tableDetailsLoading = atom<boolean>({
  key: "tableDetailsLoading",
  default: false,
});

// SEARCH

export const searchParams = atom<string | null>({
  key: "searchParams",
  default: null,
});

export const searchQueryParams = atom<SearchQueryParamsStateType>({
  key: "searchQueryParams",
  default: pagesDefaultValue(null),
});

export const noSearchResults = atom<boolean>({
  key: "noSearchResults",
  default: false,
});

// DETAILS SECTION:

export const tableDetailsSectionState = atom<boolean>({
  key: "tableDetailsSectionState",
  default: false,
});

export const resetDetailsSectionOnPageChange = atom<boolean>({
  key: "resetDetailsSectionOnPageChange",
  default: false,
});

export const emailTransferDetailsState = atom<EmailTransferObjectDto[] | null>({
  key: "emailTransferDetailsState",
  default: null,
});

// ACTIONS

export const tableInlineActions = atom<any[]>({
  key: "tableInlineActions",
  default: [],
});

export const fileDownloadProgress = atom<number | null>({
  key: "fileDownloadProgress",
  default: null,
});

// VEHICLE SCHEDULES

export const selectedVehicleSchedule = atom<ScheduleDataDto | null>({
  key: "selectedVehicleSchedule",
  default: null,
});

// SCHEDULES

export const selectedResourceSchedule = atom<ScheduleDataDto | null>({
  key: "selectedResourceSchedule",
  default: null,
});

// ERRORS
export const serverError = atom<any>({
  key: "serverError",
  default: undefined,
});

// REFRESH
export const tokenRefreshState = atom<boolean>({
  key: "tokenRefreshState",
  default: false,
});

export const refreshTableState = atom<boolean>({
  key: "refreshTableState",
  default: false,
});

// FILTERS
export const filtersState = atom<boolean>({
  key: "filtersState",
  default: false,
});

export const filterQueryParams = atom<FilterParamsType>({
  key: "filterQueryParams",
  default: pagesDefaultValue(null),
});

export const filtersHeight = atom<number>({
  key: "filtersHeight",
  default: 0,
});

export const filterRefreshTable = atom<boolean>({
  key: "filterRefreshTable",
  default: true,
});

export const noResultClear = atom<boolean>({
  key: "noResultClear",
  default: false,
});

export const companyListForCompanySelector = atom<CompaniesDto[]>({
  key: "companyListForCompanySelector",
  default: [],
});

export const rootCompanyListForCompanySelector = atom<CompaniesDto[]>({
  key: "rootCompanyListForCompanySelector",
  default: [],
});

// DASHBOARD

export const newsDataState = atom<NewsDto[] | null>({
  key: "newsDataState",
  default: null,
});

export const deviceStatusState = atom<DeviceStatusDto | null>({
  key: "deviceStatusState",
  default: null,
});

export const companyCardsState = atom<CompanyCardDataDto[] | null>({
  key: "companyCardsState",
  default: null,
});

export const newsScrollOffset = atom<number>({
  key: "newsScrollOffset",
  default: 0,
});

// TUTORIALS

export const currTutorialDetails = atom<TutorialDetailsType>({
  key: "currTutorialDetails",
  default: {
    isTutorialOpen: false,
    stepIndex: 0,
    stepGroup: STEP_GROUP.Default,
    isTutorialActive: false,
  },
});

export const settingsStatus = atom<boolean>({
  key: "settingsStatus",
  default: false,
});

// LOCATION

export const prevLocationState = atom<string | undefined>({
  key: "prevLocationState",
  default: undefined,
});
