import { useContext } from "react";
import { styled } from "@mui/material/styles";
import MuiTooltip, {
  tooltipClasses,
  TooltipProps as MuiTooltipProps,
} from "@mui/material/Tooltip";

import { TooltipSizes } from "./types";
import { getArrowPosition } from "./functions";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface TooltipProps {
  size?: TooltipSizes;
  hasArrow?: boolean;
  fromAutocomplete?: boolean;
  maxWidth?: string;
  minWidth?: string;
  customWordBreak?: string;
}

const Tooltip = styled(
  ({
    className,
    size = "small",
    hasArrow,
    customWordBreak,
    fromAutocomplete,
    maxWidth,
    minWidth,
    ...props
  }: TooltipProps & MuiTooltipProps) => (
    <MuiTooltip
      {...props}
      classes={{ popper: className }}
      arrow={hasArrow === undefined ? size !== "small" : hasArrow}
    />
  )
)(({ size = "small", customWordBreak, ...props }) => {
  const { colors } = useContext(ThemeContext);

  const arrowPosition = getArrowPosition(props);
  const smallTooltip = size === "small";
  const largeTooltip = size === "large";

  const smallTooltipPadding = smallTooltip && "8px 16px";
  const largeTooltipPadding = largeTooltip && "16px";

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.gray700,
      color: colors.white,
      fontWeight: "400",
      fontSize: size === "small" ? "12px" : "14px",
      lineHeight: size === "small" ? "16px" : "20px",
      letterSpacing: size === "small" ? "0.4px" : "0.1px",
      padding: smallTooltipPadding || largeTooltipPadding || "12px 24px",
      borderRadius: "8px",
      maxWidth: props.maxWidth,
      minWidth: props.minWidth,
      whiteSpace: "pre-line",
      wordBreak: customWordBreak ?? "initial",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: colors.gray700,
      width: "0",
      height: "0",
      ...arrowPosition,
    },
    [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
      marginBottom: "8px !important",
    },
    [`&[data-popper-placement*="top-end"] .${tooltipClasses.tooltip}`]: {
      marginBottom: props.fromAutocomplete
        ? "-10px !important"
        : "4px !important",
    },
    [`&[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
      marginTop: "4px !important",
    },
    [`&[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
      marginRight: "4px !important",
    },
    [`&[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
      marginLeft: "4px !important",
    },
    [`&[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderTop: `4px solid ${colors.gray700}`,
      marginBottom: "-4px",
    },
    [`&[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]: {
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderBottom: `4px solid ${colors.gray700}`,
      marginTop: "-4px",
    },
    [`&[data-popper-placement*="left"] .${tooltipClasses.arrow}`]: {
      borderTop: "6px solid transparent",
      borderBottom: "6px solid transparent",
      borderLeft: `4px solid ${colors.gray700}`,
      marginRight: "-4px",
      width: "0",
      height: "0",
    },
    [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
      borderTop: "6px solid transparent",
      borderBottom: "6px solid transparent",
      borderRight: `4px solid ${colors.gray700}`,
      marginLeft: "-4px",
      width: "0",
      height: "0",
    },
  };
});

export default Tooltip;
